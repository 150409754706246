import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import CopyrightYear from "./CopyrightYear"
import { ReactSVG } from "react-svg"

const FooterBottomStyled = styled.div`
  text-align: center;
  background-color: #b6212a;
  color: #fff;
  padding: 20px;
  line-height: 24px;
  a {
    color: #fff;
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 0;
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .gatsby-image-wrapper {
      margin-left: 20px;
    }
  }
`

const FooterBottom = () => {
  const FOOTER_LOGO = graphql`
    query GET_FOOTER_LOGO {
      file(relativePath: { eq: "nexvel-logo.svg" }) {
        publicURL
      }
    }
  `
  return (
    <StaticQuery
      query={FOOTER_LOGO}
      render={props => (
        <>
          <FooterBottomStyled>
            &copy; &nbsp;
            <CopyrightYear /> &nbsp;Mednick, Mezyk &amp; Kredo, PC, All Rights
            Reserved |&nbsp; <Link to="/privacy-policy/">Privacy Policy</Link>
            &nbsp; |&nbsp; <Link to="/sitemap/">Site Map</Link>
            <a href="https://www.nexvelsolutions.com/" target="_blank">
              <ReactSVG
                src={props.file.publicURL}
                alt="Website Designed by Nexvel Solutions"
                loading="lazy"
              />
            </a>
          </FooterBottomStyled>
        </>
      )}
    />
  )
}
export default FooterBottom
